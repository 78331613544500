import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Pagination from 'react-js-pagination';

import { Link } from '../../components/common/Misc';
import Header from '../../components/common/Header';
import SubHeader from '../../components/common/SubHeader';

import shortenString from '../../helpers/string';
import styles from './styles';

const linkStyles = css`
  box-shadow: none;
`;

const Comunicados = (props) => {
    const itemsCountPerPage = 9;
    const [page, setPage] = useState(1);
    const { data: { strapiGrupoComunicados: grReleases } } = props;
    return (
        <main css={styles}>
            <Header section="news" />
            <SubHeader title={grReleases.name} />
            <div className="back-to">
                <Link
                    to="/noticias"
                    rel="nofollow noopener noreferrer"
                >
                    &larr; Noticias/Prensa
                </Link>
            </div>
            <div className="releases">
                {grReleases.releases
                    .map((post, i) => {
                        const max = page * itemsCountPerPage;
                        const min = max - itemsCountPerPage;
                        if (i >= min && i < max) {
                            return (
                                <div
                                    key={post.id}
                                    className="release"
                                >
                                    <Link
                                        to={`/comunicado${post.slug}`}
                                        className={linkStyles}
                                        rel="nofollow noopener noreferrer"
                                    >
                                        <h4 title={post.title}>{shortenString(post.title, 50)}</h4>
                                        <h6>Leer más</h6>
                                    </Link>
                                </div>
                            );
                        }
                        return null;
                    })}
            </div>
            <Pagination
                activePage={page}
                pageRangeDisplayed={3}
                itemsCountPerPage={itemsCountPerPage}
                onChange={(newPage) => setPage(newPage)}
                totalItemsCount={grReleases.releases.length}
            />
        </main>
    );
};

export const pageQuery = graphql`
  query ComunicadosByPath($slug: String) {
    strapiGrupoComunicados(slug: { eq: $slug }) {
        name
        releases {
            slug
            id
            title
        }
    }
  }
`;

Comunicados.propTypes = {
    data: PropTypes.object.isRequired
};

export default Comunicados;
